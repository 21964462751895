<template>
  <!-- 弹窗修改模拟面试信息 -->
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    :show-close="!isFirst"
    :close-on-click-modal="false"
  >
    <div class="container">
      <div class="title">
        {{ isFirst ? "参加面试需补填下列信息" : "修改信息" }}
      </div>
      <div class="form">
        <el-form
          :model="userForm"
          label-position="right"
          label-width="150px"
          :rules="rules"
          ref="userMockForm"
        >
          <el-form-item label="Nickname：" prop="nickName">
            <el-input v-model="userForm.nickName"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="userForm.email"></el-input>
          </el-form-item>
          <el-form-item label="Skype用户名：" prop="skypeUserName">
            <el-input v-model="userForm.skypeUserName"></el-input>
          </el-form-item>
          <el-form-item label="Skype邮箱：" prop="skypeEmail">
            <el-input v-model="userForm.skypeEmail"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div slot="footer" style="text-align: center">
      <el-button type="primary" size="small" @click="confirmUserMock"
        >确定</el-button
      >
      <el-button type="default" size="small" v-if="!isFirst">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { saveUserMockData } from "@/service/mock";
export default {
  name: "editUserMockData",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
    },
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      userForm: {},
      rules: {
        nickName: [
          {
            required: true,
            message: "请输入Nickname",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入email",
            trigger: "blur",
          },
        ],

        skypeUserName: [
          {
            required: true,
            message: "请输入Skype用户名",
            trigger: "blur",
          },
        ],
        skypeEmail: [
          {
            required: true,
            message: "请输入Skype邮箱",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeVisible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.userForm = { ...this.userData };
      }
    },
  },
  methods: {
    confirmUserMock() {
      this.$refs.userMockForm.validate((valid) => {
        if (valid) {
          saveUserMockData(this.userForm).then((res) => {
            if (res.success) {
              this.$message.success("保存成功！");
              this.dialogVisible = false;
              this.$emit("reload");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-bottom: 30px;
}
.container {
  text-align: center;
  padding: 0 30px;
}
::v-deep .el-dialog__body {
  padding: 0 30px;
}
::v-deep .el-dialog__footer .el-button {
  width: 100px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
</style>
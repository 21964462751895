<template>
<!-- 模拟面试 -->
  <div id="app">
    <div class="center-tit">模拟面试<span>/Mock Interview</span></div>

    <div
      v-if="
        userMockData.email &&
        userMockData.nickName &&
        userMockData.skypeEmail &&
        userMockData.skypeUserName
      "
    >
      <div class="details">
        <div class="title">修改信息</div>
        <div class="forms">
          <el-form
            :label-position="labelPosition"
            :model="userMockData"
            label-width="150px"
          >
            <el-form-item label="Nickname：">
              {{ userMockData.nickName }}
            </el-form-item>
            <el-form-item label="邮箱：">
              {{ userMockData.email }}
            </el-form-item>
            <el-form-item label="Skype用户名：">
              {{ userMockData.skypeUserName }}
            </el-form-item>
            <el-form-item label="Skype邮箱：">
              {{ userMockData.skypeEmail }}
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            size="small"
            class="editData"
            @click="editVisible = true"
            >修改信息</el-button
          >
        </div>
      </div>
      <div class="lists">
        <div class="title">拥有面试</div>
        <div class="list-conss">
          <div
            class="mockInterviews"
            v-for="(interview, index) in interviews"
            :key="index"
          >
            <img class="cover" :src="interview.cover" alt="" />
            <div class="rightContent">
              {{ interview.name }}
              <div class="operateArea">
                <el-button
                  type="primary"
                  size="small"
                  @click="uploadCv(interview.id)"
                  >上传简历</el-button
                >
                <el-button
                  v-if="interview.status && interview.status.value == 0"
                  @click="switchMockMatching(interview)"
                  size="small"
                  type="success"
                  >开始匹配</el-button
                >
                <el-button
                  size="small"
                  @click="openProcedure(interview)"
                  v-else-if="
                    interview.status &&
                    interview.status.value != 0 &&
                    interview.status.value != 1
                  "
                >
                  <template
                    v-if="interview.status && interview.status.value == 2"
                    >等待确认</template
                  >
                  <template
                    v-if="interview.status && interview.status.value == 3"
                    >确认时间</template
                  >

                  <template
                    v-if="interview.status && interview.status.value == 4"
                    >待面试</template
                  >
                  <template
                    v-if="interview.status && interview.status.value == 6"
                    >待评价</template
                  >
                </el-button>
                <el-button
                  v-if="interview.status && interview.status.value == 1"
                  @click="switchMockMatching(interview)"
                  size="small"
                  type="danger"
                  >停止匹配</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditUserMockData
      :visible="editVisible"
      :is-first="isFirst"
      @changeVisible="(val) => (editVisible = val)"
      :userData="userMockData"
      @reload="reload"
    ></EditUserMockData>
    <MockInterviewProcedure
      :visible="procedureVisible"
      :data="currentData"
      @changeVisible="(val) => (procedureVisible = val)"
      @reload="reload"
    ></MockInterviewProcedure>
    <el-upload
      :action="uploadUrl"
      :on-success="handleCvSuccess"
      :before-upload="beforeUpload"
      :show-file-list="false"
      :limit="1"
      accept=".pdf,.PDF"
      :headers="{ 'X-Access-Token': token }"
      :data="{ userId: userInfo.id }"
    >
      <i class="el-icon-plus" ref="uploadCv"></i>
    </el-upload>
  </div>
</template>
<script>
import {
  getMockJobSeeker,
  getMockInterviewOrderList,
  updateResume,
  switchMockMatching,
} from "@/service/mock";
import EditUserMockData from "./editUserMockData";
import MockInterviewProcedure from "./mockInterviewProcedure";
import { mapState } from "vuex";
export default {
  name: "mockInterview",
  components: { EditUserMockData, MockInterviewProcedure },
  data() {
    return {
      interviews: [],
      labelPosition: "right",
      userMockData: {
        email: "",
        nickName: "",
        skypeEmail: "",
        skypeUserName: "",
      },
      editVisible: false,
      isFirst: false,
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/uploadResume",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      currentId: "",
      procedureVisible: false,
      currentData: {},
    };
  },
  mounted() {
    this.getMockJobSeeker();
  },
  methods: {
    getMockJobSeeker() {
      getMockJobSeeker().then((res) => {
        if (res.success) {
          if (
            res.result.email &&
            res.result.nickName &&
            res.result.skypeEmail &&
            res.result.skypeUserName
          ) {
            this.isFirst = false;
            this.userMockData = res.result;
            this.getMockInterviewOrderList();
          } else {
            this.isFirst = true;
            this.editVisible = true;
          }
        }
      });
    },
    getMockInterviewOrderList() {
      getMockInterviewOrderList().then((res) => {
        if (res.success) {
          this.interviews = res.result;
        }
      });
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传简历大小不能超过 10MB!");
      }
      return isLt10M;
    },
    handleCvSuccess(res) {
      updateResume({
        id: this.currentId,
        resume: res.result.objectKey,
      }).then((r) => {
        if (r.success) {
          this.$message.success("简历上传成功！");
        }
      });
    },
    reload() {
      this.getMockJobSeeker();
      this.getMockInterviewOrderList();
    },
    uploadCv(id) {
      this.currentId = id;
      this.$refs.uploadCv.click();
    },
    openProcedure(item) {
      this.currentData = item;
      this.procedureVisible = true;
    },
    switchMockMatching(item) {
      switchMockMatching(item.id).then((res) => {
        if (res.success) {
          this.getMockJobSeeker();
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.mockInterviews {
  padding: 20px;
  border: 1px solid rgba(234, 234, 234, 1);
}
.cover {
  width: 186px;
  vertical-align: top;
}
.rightContent {
  margin-left: 12px;
  line-height: 104px;
  display: inline-block;
  width: calc(100% - 206px);
  .operateArea {
    float: right;
  }
}
.title {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  padding: 18px;
}
.details,
.lists {
  padding: 0 36px;
}
.forms {
  padding: 20px;
  position: relative;

  border: 1px solid #eaeaea;
  .editData {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
::v-deep .el-upload {
  display: none;
}
.msgBox {
  ::v-deep .el-message-box__message p {
    text-align: center !important;
  }
  ::v-deep .el-message-box__btns {
    text-align: center !important;
    padding: 15px 15px;
    .el-button.el-button--primary {
      background: linear-gradient(to left, #fd1d1d, #fcb045);
      border: none;
    }
  }
}
</style>
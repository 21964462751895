var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_vm._m(0),(
        _vm.userMockData.email &&
        _vm.userMockData.nickName &&
        _vm.userMockData.skypeEmail &&
        _vm.userMockData.skypeUserName
      )?_c('div',[_c('div',{staticClass:"details"},[_c('div',{staticClass:"title"},[_vm._v("修改信息")]),_c('div',{staticClass:"forms"},[_c('el-form',{attrs:{"label-position":_vm.labelPosition,"model":_vm.userMockData,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"Nickname："}},[_vm._v(" "+_vm._s(_vm.userMockData.nickName)+" ")]),_c('el-form-item',{attrs:{"label":"邮箱："}},[_vm._v(" "+_vm._s(_vm.userMockData.email)+" ")]),_c('el-form-item',{attrs:{"label":"Skype用户名："}},[_vm._v(" "+_vm._s(_vm.userMockData.skypeUserName)+" ")]),_c('el-form-item',{attrs:{"label":"Skype邮箱："}},[_vm._v(" "+_vm._s(_vm.userMockData.skypeEmail)+" ")])],1),_c('el-button',{staticClass:"editData",attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.editVisible = true}}},[_vm._v("修改信息")])],1)]),_c('div',{staticClass:"lists"},[_c('div',{staticClass:"title"},[_vm._v("拥有面试")]),_c('div',{staticClass:"list-conss"},_vm._l((_vm.interviews),function(interview,index){return _c('div',{key:index,staticClass:"mockInterviews"},[_c('img',{staticClass:"cover",attrs:{"src":interview.cover,"alt":""}}),_c('div',{staticClass:"rightContent"},[_vm._v(" "+_vm._s(interview.name)+" "),_c('div',{staticClass:"operateArea"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.uploadCv(interview.id)}}},[_vm._v("上传简历")]),(interview.status && interview.status.value == 0)?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.switchMockMatching(interview)}}},[_vm._v("开始匹配")]):(
                    interview.status &&
                    interview.status.value != 0 &&
                    interview.status.value != 1
                  )?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.openProcedure(interview)}}},[(interview.status && interview.status.value == 2)?[_vm._v("等待确认")]:_vm._e(),(interview.status && interview.status.value == 3)?[_vm._v("确认时间")]:_vm._e(),(interview.status && interview.status.value == 4)?[_vm._v("待面试")]:_vm._e(),(interview.status && interview.status.value == 6)?[_vm._v("待评价")]:_vm._e()],2):_vm._e(),(interview.status && interview.status.value == 1)?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.switchMockMatching(interview)}}},[_vm._v("停止匹配")]):_vm._e()],1)])])}),0)])]):_vm._e(),_c('EditUserMockData',{attrs:{"visible":_vm.editVisible,"is-first":_vm.isFirst,"userData":_vm.userMockData},on:{"changeVisible":(val) => (_vm.editVisible = val),"reload":_vm.reload}}),_c('MockInterviewProcedure',{attrs:{"visible":_vm.procedureVisible,"data":_vm.currentData},on:{"changeVisible":(val) => (_vm.procedureVisible = val),"reload":_vm.reload}}),_c('el-upload',{attrs:{"action":_vm.uploadUrl,"on-success":_vm.handleCvSuccess,"before-upload":_vm.beforeUpload,"show-file-list":false,"limit":1,"accept":".pdf,.PDF","headers":{ 'X-Access-Token': _vm.token },"data":{ userId: _vm.userInfo.id }}},[_c('i',{ref:"uploadCv",staticClass:"el-icon-plus"})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-tit"},[_vm._v("模拟面试"),_c('span',[_vm._v("/Mock Interview")])])
}]

export { render, staticRenderFns }
<template>
  <!-- 模拟面试状态框 -->
  <el-dialog :visible.sync="dialogVisible" append-to-body width="600px">
    <div class="container">
      <template v-if="data.status && data.status.value == 0">
        <div class="title">等待匹配</div>
        <div class="description">
          正在匹配面试官，预计48小时内匹配成功，请耐心等待
        </div>
      </template>
      <template v-if="data.status && data.status.value == 2">
        <div class="title">等待面试官确认时间</div>
        <div class="description">面试官正在确认面试时间，请耐心等待</div>
      </template>
      <template
        v-if="data.status && data.status.value == 3 && !haventTime && !haveTime"
      >
        <div class="title">确认时间</div>
        <div class="description">
          面试官已经确认5个大时间段
          <br />
          请选择以下任意一个时间段并填写开始面试时间
          <br />
          <div class="timeZone">
            {{ mockInfo.timeZoneType && mockInfo.timeZoneType.name }}
          </div>
          <div style="margin-top: 20px">
            <el-select
              style="margin-right: 12px"
              v-model="selectInterviewTime"
              @change="selectEvent"
              placeholder="请选择时间段"
            >
              <el-option
                v-for="(interviewTime, index) in mockInfo.selectInterviewTime"
                :key="index"
                :label="`${interviewTime.date} ${interviewTime.startTime}~${interviewTime.endTime}`"
                :value="JSON.stringify(interviewTime)"
              ></el-option>
            </el-select>
            <el-time-picker
              v-model="interviewTime"
              :picker-options="pickerOptions"
              format="HH:mm"
              value-format="HH:mm"
            ></el-time-picker>
          </div>
          <div class="haventTime" @click="haventTime = true">
            以上时间都不方便安排？
          </div>
        </div>
      </template>
      <template
        v-if="data.status && data.status.value == 3 && haventTime && !haveTime"
      >
        <div class="title">人工介入</div>
        <div class="description">
          若5个时间段都不满足，请联系客服，我们会人工进行匹配
        </div>
        <img class="qrcode" :src="qrcode && qrcode.imageUrl" alt="" />
      </template>

      <template v-if="(data.status && data.status.value == 4) || haveTime">
        <div class="title">待面试</div>
        <div class="description">
          面试时间为：{{ mockInfo.timeZoneType && mockInfo.timeZoneType.name }}
          {{ mockInfo.interviewTime }}，请准时使用Skype参加面试
          <br />
          若面试有所变动，请联系客服人员
          <br />
          <img class="qrcode" :src="qrcode && qrcode.imageUrl" alt="" />
        </div>
      </template>
      <template v-if="data.status && data.status.value == 6">
        <div class="title">面试评价</div>
        <div class="comment-container">
          <div>
            满意程度：<el-rate v-model="satisfaction" allow-half></el-rate>
          </div>
          <br />
          <el-input
            v-model="evaluate"
            type="textarea"
            placeholder="请填写评价"
            :autosize="{ minRows: 5, maxRows: 10 }"
          ></el-input>
        </div>
      </template>
    </div>
    <div slot="footer" style="text-align: center">
      <template>
        <el-button type="primary" size="small" @click="confirmEvent"
          >确定</el-button
        >
        <el-button
          v-if="
            data.status && data.status.value == 3 && !haventTime && !haveTime
          "
          type="default"
          size="small"
          @click="dialogVisible = false"
          >取消</el-button
        >
      </template>
    </div>
  </el-dialog>
</template>
<script>
import { getCourseBanner } from "@/service";
import {
  publishMockComment,
  getMockStatus,
  confirmMockInterviewTime,
} from "@/service/mock";
export default {
  name: "mockInterviewProcedure",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  data() {
    const that = this;
    return {
      satisfaction: 0,
      evaluate: "",
      qrcode: {},
      haventTime: false,
      pickerOptions: {
        selectableRange: "00:00:00 - 00:00:00",
      },
      selectInterviewTime: "",
      mockInfo: {},
      selectTime: "",
      interviewTime: "",
      selectDay: "",
      haveTime: false,
    };
  },
  methods: {
    confirmEvent() {
      if (
        this.data.status &&
        this.data.status.value == 3 &&
        !this.haventTime &&
        !this.haveTime
      ) {
        if (this.selectDay == "" || this.interviewTime == "") {
          return this.$message.warning("请选择面试时间！");
        }
        const data = {
          id: this.data.id,
          interviewTime: `${this.selectDay} ${this.interviewTime}:00`,
          accept: true,
        };
        confirmMockInterviewTime(data).then((res) => {
          if (res.success) {
            this.$emit("reload");
            this.$nextTick(() => {
              this.getMockStatus();
              this.haveTime = true;
              this.$forceUpdate();
            });
          }
        });
      } else if (this.data.status && this.data.status.value == 6) {
        if (this.satisfaction == 0 || this.evaluate == "") {
          return this.$message.warning("请填写评价！");
        }
        const data = {
          id: this.data.id,
          evaluate: this.evaluate,
          satisfaction: this.satisfaction,
        };
        publishMockComment(data).then((res) => {
          if (res.success) {
            this.$message.success("评价成功！");
            this.dialogVisible = false;
            this.$emit("reload");
          }
        });
      } else {
        this.dialogVisible = false;
      }
    },
    selectEvent(val) {
      this.interviewTime = "";
      this.pickerOptions.selectableRange = `${JSON.parse(val).startTime}:00 - ${
        JSON.parse(val).endTime
      }:00`;
      this.selectDay = JSON.parse(val).date;
    },
    getMockStatus() {
      getMockStatus(this.data.id).then((res) => {
        if (res.success) {
          this.mockInfo = res.result;
        }
      });
    },
  },
  mounted() {
    getCourseBanner(9)
      .then((res) => {
        console.log(res);
        if (!res.result.code) {
          this.qrcode = res.result[0];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeVisible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getMockStatus();
        this.satisfaction = 0;
        this.evaluate = "";
        this.selectDay = "";
        this.interviewTime = "";
        this.selectInterviewTime = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
.title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #757575;
  line-height: 21px;
}
.el-button {
  width: 100px;
}
::v-deep .el-dialog__footer {
  padding: 30px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
.comment-container {
  width: 400px;
  margin: 0 auto;
}
::v-deep .el-rate {
  display: inline-block;
  vertical-align: top;
}
.qrcode {
  width: 108px;
  height: 108px;
  margin-top: 16px;
}
.haventTime {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 12px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 32px;
}
.timeZone {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 12px;
  margin-top: 12px;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>